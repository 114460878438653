import React from 'react'

function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <p className="text-center">
                    Todos los Derechos Reservados
                </p>
            </div>
        </footer>
    )
}

export default Footer
